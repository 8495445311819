/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable quote-props */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component } from '@angular/core';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { ThemeDefault } from 'src/app/utils/themeDefault';
//import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { SPLASH, THEME, USER } from 'src/main';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  routerHidden = false;

  constructor(
    private platform: Platform,
    public alertController: AlertController,
    public loadingController: LoadingController,
    private storage: Storage,
    private themeDefault: ThemeDefault,
    public toastController: ToastController,
  ) {
    this.platform.ready().then(() => {
      this.storage.create();
      this.loadTheme();

      this.storage.get(SPLASH).then((splash) => {
        if (!splash) {
          this.routerHidden = true;
          setTimeout(() => {
            this.routerHidden = false;
            this.storage.set(SPLASH, true);
          }, 5000);
        }
      });
    });
  }

  async loadTheme() {
    this.storage.get(THEME).then((theme) => {
      if (theme) {
        if (theme === 'dark') {
          this.themeDefault.enableDark();
        } else {
          this.themeDefault.enableLight();
        }
      } else {
        this.themeDefault.enableLight();
      }
    });
  }

}
