/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './services/guard/login-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/dashboard/left-menu/left-menu.module').then(m => m.LeftMenuPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then(m => m.SignupPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/modals/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'verification',
    loadChildren: () => import('./pages/modals/verification/verification.module').then(m => m.VerificationPageModule)
  },
  {
    path: 'left-menu',
    loadChildren: () => import('./pages/dashboard/left-menu/left-menu.module').then(m => m.LeftMenuPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'states',
    loadChildren: () => import('./pages/modals/states/states.module').then(m => m.StatesPageModule)
  },
  {
    path: 'cities',
    loadChildren: () => import('./pages/modals/cities/cities.module').then(m => m.CitiesPageModule)
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./pages/modals/tutorial/tutorial.module').then(m => m.TutorialPageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/dashboard/modals/categories/categories.module').then(m => m.CategoriesPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'insurances',
    loadChildren: () => import('./pages/dashboard/modals/insurances/insurances.module').then(m => m.InsurancesPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'services',
    loadChildren: () => import('./pages/dashboard/modals/services/services.module').then(m => m.ServicesPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'professional-profile',
    loadChildren: () => import('./pages/dashboard/modals/professional-profile/professional-profile.module').then(m => m.ProfessionalProfilePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'public-search-establishment/:tokenHash',
    loadChildren: () => import('./pages/public-search-establishment/public-search-establishment.module').then(m => m.PublicSearchEstablishmentPageModule)
  },
  {
    path: 'public-search-professional/:tokenHash',
    loadChildren: () => import('./pages/public-search-professional/public-search-professional.module').then( m => m.PublicSearchProfessionalPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
