/* eslint-disable id-blacklist */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { API } from 'src/main';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderService } from 'src/app/utils/headerService';
import { EncryptionService } from '../../utils/encryption.service';
import { RequestValidationCodeModel } from 'src/app/models/request-validation-code.model';
import { UserRegisterForm } from '../../models/user-register-form.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private headerService: HeaderService,
    private encryptionService: EncryptionService
  ) { }

  register(content: any) {

    const request = {
      name: this.encryptionService.encrypt(content.name),
      last_name: this.encryptionService.encrypt(content.last_name),
      email: this.encryptionService.encrypt(content.email),
      confirmEmail: this.encryptionService.encrypt(content.confirmEmail),
      phone: this.encryptionService.encrypt(content.phone),
      password: this.encryptionService.encrypt(content.password),
      confirmPassword: this.encryptionService.encrypt(content.confirmPassword),
      gender: content.gender,
      document: this.encryptionService.encrypt(content.document),
      birth_date: this.encryptionService.encrypt(content.birth_date),
      postcode: this.encryptionService.encrypt(content.postcode),
      address: this.encryptionService.encrypt(content.address),
      street: this.encryptionService.encrypt(content.street),
      number: this.encryptionService.encrypt(content.number),
      neighborhood: this.encryptionService.encrypt(content.neighborhood),
      district: this.encryptionService.encrypt(content.district),
      city: this.encryptionService.encrypt(content.city),
      uf: this.encryptionService.encrypt(content.uf),
      type: this.encryptionService.encrypt(content.type),
    } as UserRegisterForm;

    return this.http.post(API + 'auth/register', JSON.stringify(request), {
      headers: this.headerService.setHeader(),
      observe: 'response'
    });
  }

  login(content: any) {
    return this.http.post(API + 'auth/login', JSON.stringify(content), { headers: this.headerService.setHeader(), observe: 'response' });
  }

  refresh(token: string) {
    return this.http.get(API + 'auth/refresh', { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  logout(token: string) {
    return this.http.get(API + 'auth/logout', { headers: this.headerService.setHeaderWithToken(token), observe: 'response' });
  }

  requestValidationCode(content: RequestValidationCodeModel) {

    const request = {
      email: this.encryptionService.encrypt(content.email),
      password: this.encryptionService.encrypt(content.password),
      type: this.encryptionService.encrypt(content.type)
    } as RequestValidationCodeModel;

    return this.http.post(API + 'auth/request-validation-code', JSON.stringify(request), {
      headers: this.headerService.setHeader(),
      observe: 'response'
    });
  }

  requestResetPasswordCode(content: any) {
    return this.http.post(API + 'auth/request-reset-password-code', JSON.stringify(content), { headers: this.headerService.setHeader(), observe: 'response' });
  }

  resetPassword(content: any) {
    return this.http.post(API + 'auth/reset-password', JSON.stringify(content), { headers: this.headerService.setHeader(), observe: 'response' });
  }

  updatePassword(content: any) {
    return this.http.put(API + 'update-password', JSON.stringify(content), { headers: this.headerService.setHeader(), observe: 'response' });
  }

}
