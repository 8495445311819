import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.WORKSPACE === 'prod') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));

export const MAPS_KEY = environment.GOOGLE_MAPS_API_KEY;
export const ONESIGNAL_APP_ID = environment.ONESIGNAL_APP_ID;
export const JITSI_APP_ID = environment.JITSI_APP_ID;
export const JITSI_URL = 'https://8x8.vc';

export const TOKEN = 'CUSTOMER_TOKEN';
export const USER = 'CUSTOMER_USER';
export const SPLASH = 'CUSTOMER_SPLASH';
export const LOGIN = 'CUSTOMER_LOGIN';
export const VERIFICATION = 'CUSTOMER_VERIFICATION';
export const LOCATION = 'CUSTOMER_LOCATION';
export const LOCATION_LAT = 'CUSTOMER_LOCATION_LAT';
export const LOCATION_LONG = 'CUSTOMER_LOCATION_LONG';
export const FIRST_USE = 'CUSTOMER_FIRST_USE';

export const THEME = 'CUSTOMER_THEME';

export const BASE = environment.URL_BASE;
export const API = environment.URL_BASE + '/api/';
export const TOAST_POSITION = 'top';
export const TOAST_MODE = 'ios';
export const TOAST_TIME = 5000;
export const TOAST_ICON = 'chevron-up';
export const LOADING_ICON = 'circular';
export const LIMIT_DATE = 30;
export const VIDEO_MUTED = '';
