import { Injectable } from '@angular/core';
import { LoadingController, NavController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '../notification/notification.service';
import { Storage } from '@ionic/storage-angular';
import { UserService } from '../user/user.service';
import { USER, TOKEN, SPLASH, LOADING_ICON, THEME, FIRST_USE } from 'src/main';
import { ToastService } from 'src/app/utils/toastService ';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authState = new BehaviorSubject(false);

  constructor(
    private platform: Platform,
    public navCtrl: NavController,
    public loadingController: LoadingController,
    public toastService: ToastService,
    private serviceNotification: NotificationService,
    private userService: UserService,
    private storage: Storage,
  ) {
    this.platform.ready().then(() => {
      this.storage.create();
      this.ifLoggedIn();
    });
  }

  async ifLoggedIn() {
    const user = await this.storage.get(USER);

    if (user && !this.authState.value) {
      const token = await this.storage.get(TOKEN);

      if (token) {
        const loading = await this.loadingController.create({
          message: 'Aguarde...',
          spinner: LOADING_ICON
        });

        await loading.present();

        return new Promise((resolve, reject) => {
          this.userService.refresh(token).subscribe((res: any) => {
            loading.dismiss();
            if (res.status === 201) {
              this.authState.next(true);
              this.storage.set(USER, res.body.user);

              if (this.platform.is('cordova')) {
                this.serviceNotification.start(res.body.user.id);
              }

              resolve(true);
            } else {
              this.toastService.sendMessage(res.status, res.body.message);
              this.logout();
              resolve(false);
            }
          }, err => {
            loading.dismiss();
            this.toastService.sendMessage(err.status, err.error.message);
            this.logout();
            resolve(false);
          });
        });
      } else {
        this.logout();
      }
    }
  }

  async login(data: any) {
    this.storage.remove(SPLASH);
    this.authState.next(true);
    this.storage.set(TOKEN, data.access_token);
    this.storage.set(USER, data.user);
    if (this.platform.is('cordova')) {
      this.serviceNotification.start(data.user.id);
    }
    this.navCtrl.navigateRoot(['/left-menu/home']);
  }

  async logout() {
    if (this.platform.is('cordova')) {
      this.serviceNotification.end();
    }

    const themeStorage = await this.storage.get(THEME);
    if (themeStorage) {
      this.storage.set(THEME, themeStorage);
    }

    this.storage.clear();
    this.storage.set(FIRST_USE, true);
    this.authState.next(false);
    this.navCtrl.navigateRoot(['/login']);
  }

  async isAuthenticated() {
    await this.ifLoggedIn();
    return this.authState.value;
  }
}
